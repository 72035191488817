/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import './theme/variables.scss';
/********************************************************************/
/********************** POPUP / MODALS CSS **************************/
/********************************************************************/
/*.modal-wrapper.sc-ion-modal-md {
  width:50% !important;
  max-width:800px !important;
  height:95% !important;
}*/
@media (max-width: 800px) {
    .modal-wrapper.sc-ion-modal-md {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width: 2000px) {
    .custom-modal {
        align-items: center !important;
        justify-content: center !important;

        &::part(content) {
            width: 60% !important;
            height: 90% !important;
        }
    }
}

@media (max-width: 1400px) {
    .custom-modal {
        align-items: center !important;
        justify-content: center !important;

        &::part(content) {
            width: 80% !important;
            min-width: 700px !important;
            height: 98% !important;
        }
    }
}

@media (max-width: 700px) {
    .custom-modal {
        align-items: center !important;
        justify-content: center !important;

        &::part(content) {
            width: 100% !important;
            height: 100% !important;
            min-width: fit-content !important;
            border-radius: 0px !important;
        }
    }
}

.custom-modal {
    align-items: center !important;
    justify-content: center !important;

    &::part(content) {
        width: 60%;
        height: 90%;
        min-width: 1200px;
        border-radius: 5px;
    }
}

.total-modal {
    align-items: center !important;
    justify-content: center !important;

    &::part(content) {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 2000px) {
    .little-modal {
        align-items: center !important;
        justify-content: center !important;

        &::part(content) {
            width: 40% !important;
            height: 80% !important;
            min-width: 700px !important;
        }
    }
}

@media (max-width: 1400px) {
    .little-modal {
        align-items: center !important;
        justify-content: center !important;

        &::part(content) {
            width: 60% !important;
            height: 80% !important;
            min-width: 700px !important;
        }
    }
}

@media (max-width: 700px) {
    .little-modal {
        align-items: center !important;
        justify-content: center !important;

        &::part(content) {
            width: 100% !important;
            min-width: fit-content !important;
            height: 100% !important;
            border-radius: 0px !important;
        }
    }
}

.little-modal {
    align-items: center;
    justify-content: center;

    &::part(content) {
        width: 40%;
        height: 80%;
        min-width: 500px;
        border-radius: 5px;
    }
}

@media (max-width: 2000px) {
    .large-modal {
        align-items: center;
        justify-content: center;

        &::part(content) {
            width: 40% !important;
            height: 90% !important;
        }
    }
}

@media (max-width: 1700px) {
    .large-modal {
        align-items: center;
        justify-content: center;

        &::part(content) {
            width: 60% !important;
            height: 90% !important;
        }
    }
}

@media (max-width: 1000px) {
    .large-modal {
        align-items: center;
        justify-content: center;

        &::part(content) {
            width: 100% !important;
            height: 100% !important;
            border-radius: 0px !important;
        }
    }
}

.large-modal {
    align-items: center;
    justify-content: center;

    &::part(content) {
        width: 40%;
        height: 80%;
        border-radius: 5px;
    }
}

/********************************************************************/
/********************************************************************/
/********************************************************************/

.card-coste {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    padding: 10px;
    margin: 10px;
}

.danger-text {
    color: var(--ion-color-danger);
}

.primary-text {
    color: var(--ion-color-primary);
}

.card-box {
    border-radius: 10px;
    background-color: rgba(50, 50, 50, 0.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    margin: 10px;
}

.height-100 {
    height: 100vh;
}

.back-light {
    background-color: var(--ion-color-light);
}

.ellipsis-200 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    display: block;
}

.ellipsis-200-aux {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    display: block;
}

.item-x-actividades {
    width: calc(100% - 370px);
    float: left;
}

.card-box-advertencias {
    border-radius: 10px;
    background-color: rgba(185, 123, 123, 0.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    padding: 10px;
    margin: 10px;
}

.item-100 {
    float: left;
    width: 100%;
}

.item-50 {
    float: left;
    width: 50%;
}

.item-90 {
    float: left;
    width: 90%;
}

.item-10 {
    float: left;
    width: 10%;
}

.item-950 {
    float: left;
    width: 50%;
}

.item-33 {
    float: left;
    width: 33.3%;
}

.item-30 {
    float: left;
    width: 30%;
}

.item-70 {
    float: left;
    width: 70%;
}

.item-25 {
    float: left;
    width: 25%;
}

.item-10 {
    float: left;
    width: 10%;
}

.item-20 {
    float: left;
    width: 20%;
}

.item-80 {
    float: left;
    width: 80%;
}

.item-15 {
    float: left;
    width: 15%;
}

.item-40 {
    float: left;
    width: 40%;
}

.item-60 {
    float: left;
    width: 60%;
}

.item-50-r {
    float: left;
    width: 50%;
}

@media (max-width: 700px) {
    .item-50-r {
        width: 100%;
    }
}

.item-20-r {
    float: left;
    width: 20%;
}

@media (max-width: 900px) {
    .item-20-r {
        width: 100%;
    }
}

.item-60-r {
    float: left;
    width: 60%;
}

@media (max-width: 900px) {
    .item-60-r {
        width: 100%;
    }
}

.item-25-r {
    float: left;
    width: 25%;
}

@media (max-width: 900px) {
    .item-25-r {
        width: 100%;
    }
}

.center-me {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.title-text {
    font-size: 170%;
    text-align: center;
}

.custom-spinner {
    transform: scale(3);
    /* Ajusta el valor según sea necesario */
    color: var(--ion-color-primary);
    margin-top: 150px;
    margin-bottom: 150px;
}

.landing-text-center {
    text-align: center;
    padding: 10px;
    margin-top: 10px;
}

.normal-text-left {
    text-align: left;
    color: var(--ion-color-step-850);
}

.card-box-filter {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
}

.advertencia-box-item {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 0, 0, 0.1);
    color: var(--ion-color-step-850);
    margin: 10px;
    padding: 10px;
}

.border-top-medium {
    border-top: 1px solid var(--ion-color-medium);
    margin-top: 20px;
    padding-top: 20px;
}

.title-top-statistic {
    border-bottom: 3px solid var(--ion-color-primary);
    padding-left: 20px;
}

.border-primary {
    border-bottom: 3px solid var(--ion-color-primary);
}

.border-medium {
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
}

/*ion-footer {
    position: fixed !important;
    bottom: 0 !important;
    margin-bottom: 10px !important;
    box-shadow: none !important;
}*/

.title-header {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 3px solid var(--ion-color-step-50);
    margin-left: 0.9%;
    padding-left: 20px;
    margin-top: 3px;
    font-size: 140%;
}

.icon-header-title {
    font-size: 25px;
    margin-right: 20px;
    background-color: var(--ion-color-primary);
    border-radius: 100px;
    padding: 5px;
}

.label-header-custom {
    margin-top: 0px !important;
}

@media (max-width: 1000px) {
    .title-header {
        margin-top: 0px !important;
        font-size: 100%;
    }
}

.title-header-custom {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 3px solid var(--ion-color-step-50);
    margin-left: 0.9%;
    font-size: 80%;
    padding-left: 20px;
}

@media (max-width: 1000px) {
    .title-header-custom {
        margin-top: 0px !important;
    }
}

.title-header-dashboard {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 3px solid var(--ion-color-step-50);
    margin-left: 0.9%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.text-button {
    font-size: 85%;
    font-weight: bold;
}

.input-right {
    text-align: right;
}

.title-header-box {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
}

.item-justify {
    display: flex;
    justify-content: space-between;
}

.ion-item-custom {
    border-bottom: 1px solid var(--ion-color-step-50);
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.item-activity-icon {
    font-size: 25px;
    float: left;
}

.item-box-activity {
    position: absolute;
    right: 50px;
    margin-top: 5px;
    width: 60px
}

.item-title-icon-button {
    font-size: 25px;
    float: left;
    position: absolute;
}

.item-box-title-button {
    position: absolute;
    right: 50px;
    width: 60px
}

.pointer-div {
    cursor: pointer !important;
}

.item-style {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.icon-container {
    color: var(--ion-color-step-850);
    font-size: 40px;
}

.icon-style {
    color: var(--ion-color-primary) !important;
    font-size: 35px;
    margin-bottom: 10px;
}

.label-container {
    padding: 15px 10px 15px 20px;
}

.main-label {
    color: var(--ion-color-step-950);
    font-size: 100%;
    font-weight: bold;
}

.sub-label {
    font-size: 90%;
    color: var(--ion-color-step-800);
}

.item-label {
    margin-left: 10px;
    color: var(--ion-color-step-850);
}

.primary-label {
    font-size: 110%;
    color: var(--ion-color-primary);
    padding-top: 10px;
    height: 100%;
    vertical-align: middle;
}

.medium-label {
    font-size: 110%;
    color: var(--ion-color-medium);
    padding-top: 10px;
    height: 100%;
    vertical-align: middle;
}

.primary-separator {
    border-bottom: 2px solid var(--ion-color-primary);
    vertical-align: middle;
    margin-bottom: 10px;
}

.medium-separator {
    border-bottom: 2px solid var(--ion-color-medium);
    vertical-align: middle;
    margin-bottom: 10px;
}

.role-label {
    font-size: 70%;
}

.pointer-div {
    cursor: pointer;
}

.icon-close {
    font-size: 23px;
    float: right;
}

.list-item.selected {
    background-color: var(--ion-color-primary);
}

.list-item-text {
    display: flex;
    align-items: center;
}

.list-item-text ion-icon {
    margin-right: 10px;
}

.list-item-subtext {
    margin-top: 10px;
    color: var(--ion-color-step-850);
}

.page-body {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 10px;
}

.hover-item {
    cursor: pointer !important;
}

.hover-item:hover {
    --background: var(--ion-color-light) !important;
    //shadows
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.item-x-right {
    float: right;
}

.item-x-left {
    float: left;
}

.pc-1000-only {
    position: absolute;
    visibility: hidden;
}

@media (min-width: 1000px) {
    .pc-1000-only {
        position: relative !important;
        visibility: visible !important;
    }
}

.mobile-1000-only {
    position: relative;
    visibility: visible;
}

@media (min-width: 1000px) {
    .mobile-1000-only {
        position: absolute !important;
        visibility: hidden !important;
    }
}

.mobile-800-only {
    position: relative;
    visibility: visible;
}

@media (min-width: 800px) {
    .mobile-800-only {
        position: absolute !important;
        visibility: hidden !important;
    }
}

/****************************************************************************/
/******************************TOOLTIP ******************************/
/****************************************************************************/

.tooltip-content {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
}

.tooltip-clicker {
    cursor: help;
}

.title-tooltip {
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
}

.tooltip-content {
    padding: 10px 10px 15px;
    /* Espacio adicional en la parte inferior para separación */
    position: relative;
    border-radius: 4px;
    /* Bordes redondeados para apariencia suave */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Sombra para elevar el tooltip */
    z-index: 1000;
    font-family: Arial, sans-serif;
    /* Fuente legible */
    font-size: 14px;
    /* Tamaño de fuente */
}

.tooltip-content span {
    display: block;
    margin-bottom: 5px;
}

/****************************************************************************/
/****************************** BOX ITEM LIST ******************************/
/****************************************************************************/
.item-container {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--ion-card-background);
    color: var(--ion-color-step-850);
    margin: 10px;
}

.item-container {
    width: 98%;
    float: left;
    padding: 10px;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
}

@media (min-width: 1000px) {
    .item-container {
        width: 48% !important;
        margin-right: 0% !important;
        margin-bottom: 0% !important;
        margin-left: 1% !important;
        margin-top: 1% !important;
    }
}

@media (min-width: 1400px) {
    .item-container {
        width: 32% !important;
        margin-right: 0% !important;
        margin-bottom: 0% !important;
        margin-left: 1% !important;
        margin-top: 1% !important;
    }
}

.item-container {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.item-container-big {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: var(--ion-card-background);
    color: var(--ion-color-step-850);
    margin: 10px;
}

.item-container-big {
    width: 98%;
    float: left;
    padding: 10px;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
}

@media (min-width: 1600px) {
    .item-container-big {
        width: 48% !important;
        margin-right: 0% !important;
        margin-bottom: 0% !important;
        margin-left: 1% !important;
    }
}

@media (min-width: 2400px) {
    .item-container-big {
        width: 32% !important;
        margin-right: 0% !important;
        margin-bottom: 0% !important;
        margin-left: 1% !important;
    }
}

.item-container-big {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/****************************************************************************/
/******************************************************************************/
/****************************************************************************/
.selected-item {
    color: var(--ion-color-primary);
}

.label-monte {
    margin-left: 10px;
}

.asignacion-count {
    font-size: 90%;
}

.icon-container {
    float: left;
    text-align: right;
}

.icon-leaf,
.icon-chevron,
.icon-close {
    font-size: 23px;
}

.list-item-subtext {
    border-top: 3px solid var(--ion-color-primary);
    font-size: 90%;
}

.asignacion-detail {
    background-color: var(--ion-color-light);
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
}

.asignacion-user {
    font-size: 110%;
}

.item-style {
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.1);
}

.icon-container {
    color: var(--ion-color-step-950);
    font-size: 40px;
    float: left;
}

.icon-style {
    color: var(--ion-color-primary);
    font-size: 40px;
    float: left;
}

.primary-icon-style {
    color: var(--ion-color-primary);
    font-size: 40px;
    float: left;
}

.medium-icon-style {
    color: var(--ion-color-medium);
    font-size: 40px;
    margin-right: 10px;
    float: left;
}

.label-container {
    padding: 15px 10px 15px 20px;
    border-left: 2px solid var(--ion-color-step-500);
}

.main-label {
    color: var(--ion-color-primary);
    font-size: 100%;
    font-weight: bold;
}

.sub-label {
    font-size: 90%;
    color: var(--ion-color-step-950);
}

.item-label {
    margin-left: 10px;
    color: var(--ion-color-medium);
}

.primary-label {
    font-size: 110%;
    color: var(--ion-color-primary);
    padding: 10px;
    float: left;
    text-align: left;
}

.role-label {
    font-size: 70%;
}

.pointer-div {
    cursor: pointer;
}

.icon-close {
    font-size: 23px;
    float: right;
}

.monte-header {
    font-size: 18px;
    /* Ajusta el tamaño de la fuente según tus necesidades */
    font-weight: bold;
    /* Hace que el texto sea más grueso */
    color: var(--ion-color-primary);
    /* Usa el color primario, o el que prefieras */
    padding: 10px 0;
    /* Agrega algo de espacio alrededor del texto */
    text-align: center;
    /* Centra el texto */
    border-bottom: 2px solid var(--ion-color-primary);
    /* Añade un borde como separador */
    margin-bottom: 20px;
    /* Espacio después del separador */
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    color: #ff4d4d;
    /* Color rojo para el botón de cierre */
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}

.close-button:hover {
    color: #d9534f;
    /* Color más oscuro al pasar el mouse */
}

.value-primary {
    color: var(--ion-color-primary);
    font-size: 100%;
    font-weight: bold;
}

.item-border-bottom {
    border-bottom: 1px solid rgba(100, 100, 100, 0.5);
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.center-title-text {
    color: var(--ion-color-primary);
    text-align: center;
}

.center-title-text-2 {
    color: var(--ion-color-medium);
    text-align: center;
    font-size: 160%;
}

.item-margin {
    padding: 10px;
}

.box-item {
    border-radius: 10px;
    border: 2px solid var(100, 100, 100, 0.2);
}

.box-item-nested {
    padding: 20px;
    background-color: var(--ion-item-background);
    margin-left: 10px;
    padding-top: 0px;
    width: calc(100% - 10px);
}

.item-margin-bottom {
    margin-bottom: 50px;
}

.item-padding-40 {
    padding-top: 20px;
    padding-bottom: 30px;
}

.card-box-statistic {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
}

.item-padding-10 {
    padding-top: 10px;
    padding-bottom: 20px;
}

.text-center {
    text-align: center;
}

.border-bottom {
    border-bottom: 1px solid var(--ion-color-light);
    margin-bottom: 0px;
}

.item-x {
    float: left;
}

.spinner-big {
    width: 70px !important;
    font-size: 70px;
}

.table-row-rentabilidades {
    border-bottom: 1px solid rgba(100, 100, 100, 0.5);
}

.total-value {
    color: var(--ion-color-primary);
    font-size: 200%;
    font-weight: bold;
}

.total-value-little {
    color: var(--ion-color-primary);
    font-size: 150%;
    font-weight: bold;
}

.parcial-value {
    color: var(--ion-color-primary) !important;
    font-weight: bold;
}

.parcial-value-big {
    color: var(--ion-color-primary) !important;
    font-weight: bold;
    font-size: 120% !important;
}

.white-value-big {
    color: white !important;
    font-weight: bold;
    font-size: 120% !important;
}

.white-value {
    color: white !important;
    font-weight: bold;
}

.black-value {
    color: black !important;
    font-weight: bold;
}

.back-primary {
    background-color: var(--ion-color-primary);
}

.back-tertiary {
    background-color: var(--ion-color-tertiary);
}

.back-danger-2 {
    background-color: var(--ion-color-warning);
}

.center-text {
    text-align: center;
}

.back-danger {
    background-color: var(--ion-color-danger);
}

.back-primary-dark {
    background-color: var(--ion-color-secondary);
}

.normal-value {
    color: var(--ion-color-medium);
    font-weight: bold;
}

.normal-value-big {
    color: var(--ion-color-medium);
    font-weight: bold;
    font-size: 120% !important;
}

.danger-value {
    color: var(--ion-color-danger);
    font-weight: bold;
}

.box-pages-bar {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(100, 100, 100, 0.1);
    color: var(--ion-color-step-850);
    margin: 5px;
    padding: 5px;
    margin-top: 20px;
}

/* PAGINATION CSS */
.custom-pagination {
    display: flex;
    justify-content: center;

    .ngx-pagination {
        display: flex;
        list-style: none;
        padding: 0;

        li {
            margin: 0 5px;

            &.current {
                font-weight: bold;
                background-color: var(--ion-color-primary);
                color: white;
                border-radius: 5px;
            }

            a {
                display: block;
                padding: 5px 10px;
                text-decoration: none;
                color: var(--ion-color-primary);
                border: 1px solid var(--ion-color-primary);
                border-radius: 5px;
                transition: background-color 0.3s, color 0.3s;

                &:hover {
                    background-color: var(--ion-color-primary);
                    color: white;
                }
            }

            &.disabled a {
                color: var(--ion-color-secondary);
                border-color: var(--ion-color-secondary);
                pointer-events: none;
            }
        }
    }
}

/*************************************************************************/
/***************************** DATE PICKER *******************************/
/*************************************************************************/
.date-input {
    padding: 5px;
    border-radius: 4px;
    font-size: 16px;
}

.form-control {
    margin-top: 10px;
    float: left;
    margin-bottom: 10px;
    background-color: var(--ion-color-light);
    color: var(--ion-color-primary);
    font-weight: bold;
    padding: 10px;
    border: none;
}